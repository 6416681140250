import React from 'react'

const Footer = () => {
    return(
        <footer>
            <p>Copyright © {new Date().getFullYear()} Hidekazu Kubota</p>
        </footer>
    )
}

export default Footer